import * as React from "react";
import { Menu } from "antd";
import {
  Routes,
  Route,
  Navigate,
  useParams,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { observer } from "mobx-react-lite";
import { AccountInfo } from "./AccountInfo";
import { AccountOrg } from "./AccountOrg";
import { AccountUser } from "./AccountUser";
import { AccountInvoice } from "./AccountInvoice";
import { AccountAccState } from "./AccountState/AccountAccState";

type RouteParams = "uuid";
const AccountDetailRaw: React.FC = () => {
  const { uuid: _uuid } = useParams<RouteParams>();
  const uuid = _uuid!;

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [menuActiveKey, setMenuActiveKey] = React.useState("info");
  function updateMenuActiveKey() {
    let _menuActiveKey = "info";
    switch (true) {
      case pathname.includes("/info"):
        _menuActiveKey = "info";
        break;
      case pathname.includes("/org"):
        _menuActiveKey = "org";
        break;
      case pathname.includes("/user"):
        _menuActiveKey = "user";
        break;
      case pathname.includes("/invoice"):
        _menuActiveKey = "invoice";
        break;
      case pathname.includes("/state"):
        _menuActiveKey = "state";
        break;
      default:
        break;
    }
    setMenuActiveKey(_menuActiveKey);
  }
  React.useEffect(() => {
    updateMenuActiveKey();
  }, [pathname]);

  return (
    <div className="flex p-8">
      <Menu
        theme={"light"}
        selectedKeys={[menuActiveKey]}
        onClick={({ key }) => navigate(key)}
        items={[
          { key: "info", label: "Info" },
          { key: "org", label: "Orgs" },
          { key: "user", label: "Users" },
          { key: "invoice", label: "Invoices" },
          { key: "state", label: "State" },
        ]}
      />
      <Routes>
        <Route path={`info/*`} element={<AccountInfo />} />
        <Route path={`org/*`} element={<AccountOrg />} />
        <Route path={`user/*`} element={<AccountUser />} />
        <Route path={`invoice/*`} element={<AccountInvoice />} />
        <Route path={`state/*`} element={<AccountAccState />} />
        <Route index element={<Navigate to={`info`} replace />} />
      </Routes>
    </div>
  );
};

export const AccountDetail = observer(AccountDetailRaw);
